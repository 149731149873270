<template>
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="submit" class="form-horizontal">
          <div class="form-body">
            <h3 class="box-title">Input Surat Masuk Fisik</h3>
            <hr class="m-t-0 m-b-40">
            <div class="row">
              <div class="col-md-6">
                <Input
                  v-model="payload.sender"
                  name="pengirim"
                  required
                  type="text"
                  label="Pengirim"
                />
              </div>
              <div class="col-md-6">
                <Input
                  v-model="payload.recivedDate"
                  name="recivedDate"
                  required
                  type="date"
                  label="Tanggal di terima"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <Input v-model="payload.to" name="kepada" required type="text" label="Kepada"/>
              </div>
              <div class="col-md-6">
                <Input v-model="payload.copycat" name="tembusan" type="text" label="Tembusan"/>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group row">
                  <label :class="['control-label', {'text-right': !isMobile}, 'col-md-3']">Tujuan</label>
                  <div :class="['col-md-9',{ 'invalid': isInvalid }]">
                    <Multiselect
                      placeholder="Pick at least one"
                      select-label="Enter doesn’t work here!"
                      :value="value"
                      :options="options"
                      :multiple="true"
                      :searchable="true"
                      :allow-empty="false"
                      :hide-selected="true"
                      :max-height="150"
                      :max="3"
                      :disabled="isDisabled"
                      :block-keys="['Tab', 'Enter']"
                      @input="onChange"
                      @close="onTouch"
                      @select="onSelect"
                    ></Multiselect>
                    <label
                      class="typo__label form__label"
                      v-show="isInvalid"
                    >Must have at least one value</label>
                  </div>
                </div>
              </div>
            </div>
            <hr class="m-t-0 m-b-40">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group row">
                  <label :class="['control-label', {'text-right': !isMobile}, 'col-md-3']">Index</label>
                  <div :class="['col-md-9',{ 'invalid': isInvalid }]">
                    <Multiselect
                      placeholder="Pick at least one"
                      select-label="Enter doesn’t work here!"
                      :value="value"
                      :options="options"
                      :multiple="true"
                      :searchable="true"
                      :allow-empty="false"
                      :hide-selected="true"
                      :max-height="150"
                      :max="3"
                      :disabled="isDisabled"
                      :block-keys="['Tab', 'Enter']"
                      @input="onChange"
                      @close="onTouch"
                      @select="onSelect"
                    ></Multiselect>
                    <label
                      class="typo__label form__label"
                      v-show="isInvalid"
                    >Must have at least one value</label>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <Input
                  v-model="payload.mailtype"
                  required
                  name="sifat surat"
                  type="text"
                  label="Sifat Surat"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <Input
                  v-model="payload.mailheader"
                  name="Nomer Surat"
                  required
                  type="text"
                  label="Nomer Surat"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <Input
                  v-model="payload.handsonMailHeader"
                  name="Nomer Dokument Fisik"
                  type="text"
                  label="Nomer Dokument Fisik"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <Input
                  v-model="payload.fileDate"
                  name="Tanggal Dokument"
                  required
                  type="date"
                  label="Tanggal Dokument"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <Input
                  v-model="payload.subjectMatter"
                  name="perihal"
                  required
                  type="text"
                  label="Prihal"
                />
              </div>
            </div>
          </div>
          <hr>
          <div class="form-actions">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-offset-3 col-md-9">
                    <button type="submit" class="btn btn-success" title="Simpan">Submit</button>&nbsp;
                    <button type="button" @click="goBack()" class="btn btn-inverse" title="Cancel">Cancel</button>
                  </div>
                </div>
              </div>
              <div class="col-md-6"></div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Input from "../../../components/input/input";
import Multiselect from "vue-multiselect";

export default {
  components: {
    Input,
    Multiselect
  },
  computed: {
    editMode() {
      return !!this.$route.params.id;
    },
    isInvalid() {
      return this.isTouched && this.value.length === 0;
    },
    isMobile() {
      return this.$store.state.isMobile;
    }
  },
  data() {
    return {
      value: [],
      options: [
        "Select option",
        "Disable me!",
        "Reset me!",
        "Admin Dinas Komunikasi dan Informatika ( Kepala Seksi Dinas Komunikasi dan Informatika )",
        "label",
        "searchable"
      ],
      payload: {
        to: "Gubernur"
      },
      isTouched: false,
      isDisabled: false
    };
  },
  async mounted() {
    this.initialize();
  },

  methods: {
    async initialize() {
      try {
        if (this.editMode) {
          this.$store.dispatch("suratFisik/onEdit");
        } else {
          this.$store.dispatch("suratFisik/onAdd");
        }
      } catch (e) {
        this.handleApiError(e);
        this.error = e.message;
      }
    },
    onTouch() {
      this.isTouched = true;
    },
    onChange(value) {
      this.value = value;
      if (value.indexOf("Reset me!") !== -1) this.value = [];
    },
    onSelect(option) {
      if (option === "Disable me!") this.isDisabled = true;
    },
    goBack() {
      this.$store.dispatch("suratFisik/onCancel");
    },
    submit() {
      if (this.value.length === 0) {
        this.isTouched = true;
      }
      this.$validator.validateAll().then(success => {
        if (success && !this.isInvalid) {
            //buat contoh
            this.isDisabled = false
        }
      });
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da !important;
}
.invalid .multiselect__tags {
  border-color: #f04124 !important;
  padding: 5px 40px 0 8px !important;
}
.invalid .typo__label {
  color: #f04124;
  font-size: 0.8125rem;
}
.multiselect {
  color: #54667a !important;
}
.multiselect__select {
  height: 41px !important;
}
</style>

